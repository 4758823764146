import React, { useState, useEffect, useCallback } from "react"
import { Link, navigate } from "gatsby"
import { Container, Jumbotron, Button } from "react-bootstrap"

import SEO from "../../components/seo"
import ContainerWrapper from "../../components/container"
import Error from "../../components/partials/error"
import Loading from "../../components/partials/loading"

import withState from "../../util/withState"
import { onRequest } from "../../util/service"

const VerifyEmail = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    status: undefined,
    message: null,
  })

  const getQueryParams = useCallback(() => {
    try {
      const paramsBag = new URLSearchParams((location || {}).search)
      const params = {}
      for (const [k, v] of paramsBag.entries()) {
        params[k] = decodeURIComponent(v)
      }
      return params
    } catch (e) {
      console.error(e)
    }
  }, [location])

  const handleServerResponse = (status, message) => {
    setError({
      status,
      message,
    })
  }

  const verifyURL = (getQueryParams() || {}).verifyURL

  useEffect(() => {
    setLoading(true)
    onRequest({
      endpoint: verifyURL,
      params: {},
      onSuccess: ({ message }) => {
        handleServerResponse(false, message)
        if (typeof window !== "undefined") {
          // doLogout()
          navigate("/login", {
            redirectedFrom: "/auth/verifiy-email",
            state: {
              msg: [
                {
                  type: "success",
                  body:
                    "You have Successfully Verfied your email, you can now login",
                  title: "Email has been verified",
                },
              ],
            },
          })
        }
      },
      onFailed: ({ data: error }) => {
        handleServerResponse(
          true,
          (error || {}).message || "Error in verifying email"
        )
      },
      onAnyCase: () => {
        setLoading(false)
      },
    })
  }, [verifyURL])

  return (
    <>
      <SEO title="Password Verification" keywords={[`mazrui`, `careers`]} />
      <ContainerWrapper
        classNames="main auth-section auth-email-verification"
        fluid
      >
        <Container>
          <>
            {typeof (error || {}).status === "undefined" || loading ? (
              <>
                <Loading />
                <p>Verifying your email...</p>
              </>
            ) : null}
            {typeof (error || {}).status === "boolean" &&
            error.status === true ? (
              <Error
                message={
                  (error || {}).message || "Error in verifying your email"
                }
                customButtons={[
                  {
                    url: `/login`,
                    label: "Login to resend verification URL",
                  },
                  {
                    url: `/forgot-password`,
                    label: "Forgot Password",
                    color: "warning",
                  },
                ]}
              />
            ) : null}
            {typeof (error || {}).status === "boolean" &&
            error.status === false ? (
              <Jumbotron>
                <p className="text-center fs-200">Successfully Verified</p>

                <Button variant="success" block className="btn-d-border">
                  <Link to="/login">Click here to Login</Link>
                </Button>
              </Jumbotron>
            ) : null}
          </>
        </Container>
      </ContainerWrapper>
    </>
  )
}

export default withState(VerifyEmail)
